import React, { Component } from "react"

import { IconContext } from "react-icons";
import { FaGithub, FaKaggle, FaLinkedin, FaFilePdf, FaTwitter } from 'react-icons/fa';
import { SiGooglescholar } from 'react-icons/si'
import { IoMdMail } from 'react-icons/io'

import "../styles/nav.scss"

import Logo from "../images/logo.png"

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
    };
  }

  componentDidMount() {
    this.setState({
      nav: document.querySelector("#inner-header"),
      toggled: localStorage.getItem("data-theme") === "dark" ? true : false,
      email: false,
    })
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 80){
      this.state.nav.classList.add('fixed')
    }
    else{
      this.state.nav.classList.remove('fixed')
    }
  }

  handleClick = () => {
    this.setState({ toggled: !this.state.toggled });
    if(this.state.toggled){
      document.documentElement.setAttribute("data-theme", "light")
      localStorage.setItem("data-theme", 'light')
    }else{
      document.documentElement.setAttribute("data-theme", "dark")
      localStorage.setItem("data-theme", 'dark')
    }
  }

  generate_email = () => {
    const list = ["99psam", "gmail.com"]
    return list[0] + "@" + list[1]
  }

  show_email = () => {
    const email = this.generate_email()
    const email_text = document.querySelector("#email-text")
    email_text.innerHTML = email
    const links = document.querySelector(".links")
    if (this.state.email){
      links.classList.remove('email')
      this.setState({ email: false })
    }else{
      links.classList.add('email')
      this.setState({ email: true })
    }
  }

  scroll_to = (id) => {
    let height = document.getElementById('inner-header').offsetHeight;
    document.getElementById(id).scrollIntoView();
    window.scrollBy(0,-height);
  }

  render() {
    return (
      <header className="nav">
        <div className="blank">
          {/* <div className="paper-container">
            <div className="paper"></div>
            <div className="inside-paper">
              <p>Neon</p>
              <p>Mode</p>
            </div>
          </div> */}
        </div>
        <div className="fake">
          <div className="center">
            <div className="inner-center">
              <h1 className="name">
                Parsa
              </h1>
              <h1 className="last">
                Sam
              </h1>
            </div>
          </div>
        </div>
        <div id="inner-header">
          <div className="left">
            <div className="inner-left">
              <a onClick={()=>this.scroll_to("about")}>About</a>
              <a onClick={()=>this.scroll_to("education")}>Education</a>
              {/* <a onClick={()=>this.scroll_to("publications")}>Publications</a>
              <a onClick={()=>this.scroll_to("projects")}>Projects</a> */}
              <a onClick={()=>{}}>Publications</a>
              <a onClick={()=>{}}>Projects</a>
            </div>
          </div>
          <div className="center">
            <div className="inner-center">
              <div className="logo">
                <img alt="" src={Logo}/>
              </div>
              <h1 className="name">
                Parsa
              </h1>
              <h1 className="last">
                Samadnejad
              </h1>
            </div>
          </div>
          <div className="right">
            <div className="inner-right">
              <a onClick={()=>this.scroll_to("contact")}>Contact</a>
              <a href="https://blog.psam.xyz" target="_blank" title="Sam's Blog" className="blog">Sam's Blog</a>
              <a href="https://ctf.psam.xyz" target="_blank" title="CAPTURE THE FLAG">
                <div className="glitch" data-text="CAPTURE THE FLAG">CAPTURE THE FLAG</div>
              </a>
              {/* <div className="toggle-container">
                <Toggle toggled={this.state.toggled} onClick={this.handleClick}/>
              </div> */}
              <button toggled={this.state.toggled} onClick={this.handleClick} class="theme-toggle" id="theme-toggle" title="Toggles light & dark" aria-label="auto" aria-live="polite">
                <svg class="sun-and-moon" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24">
                  <mask class="moon" id="moon-mask">
                    <rect x="0" y="0" width="100%" height="100%" fill="white" />
                    <circle cx="24" cy="10" r="6" fill="black" />
                  </mask>
                  <circle class="sun" cx="12" cy="12" r="6" mask="url(#moon-mask)" fill="currentColor" />
                  <g class="sun-beams" stroke="currentColor">
                    <line x1="12" y1="1" x2="12" y2="3" />
                    <line x1="12" y1="21" x2="12" y2="23" />
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                    <line x1="1" y1="12" x2="3" y2="12" />
                    <line x1="21" y1="12" x2="23" y2="12" />
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="links">
          <div className="email-container">
            <p id="email-text">Parsa'sEmail</p>
          </div>
          <div className="inner-links">
            <IconContext.Provider value={{ size: "1.1em" }}>
              <a onClick={this.show_email} rel="noreferrer" className="icon">
                <IoMdMail/>
              </a>
            </IconContext.Provider>
            <IconContext.Provider value={{ size: "1.1em" }}>
              <a href="https://github.com/troddenspade" target="_blank" rel="noreferrer" className="icon">
                <FaGithub/>
              </a>
            </IconContext.Provider>
            <IconContext.Provider value={{ size: "1.1em" }}>
              <a href="https://twitter.com/troddenspade" target="_blank" className="icon">
                <FaTwitter/>
              </a>
            </IconContext.Provider>
            <IconContext.Provider value={{ size: "1.1em" }}>
              <a href="https://kaggle.com/parsasam" target="_blank" className="icon">
                <FaKaggle/>
              </a>
            </IconContext.Provider>
            <IconContext.Provider value={{ size: "1.1em" }}>
              <a href="https://www.linkedin.com/in/parsa-samadnejad-767ab6114" target="_blank" className="icon">
                <FaLinkedin/>
              </a>
            </IconContext.Provider>
            {/* <IconContext.Provider value={{ size: "1.1em" }}>
              <a href="" target="_blank" className="icon">
                <SiGooglescholar/>
              </a>
            </IconContext.Provider> */}
            {/* <IconContext.Provider value={{ size: "1.0em" }}>
              <a href="/documents/cv.pdf" target="_blank" rel="noreferrer" className="icon">
                <FaFilePdf/><p> CV</p>
              </a>
            </IconContext.Provider> */}
          </div>
        </div>
      </header>
    )
  }
}

export default Nav