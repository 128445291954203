import * as React from "react"

import "../styles/about.scss"

import ProfilePic from '../images/profile.jpeg'
import GlassesW from '../images/g-w.png'

const About = () => {
    return (
      <div className="about" id="about">
          <div className="pic">
            <img alt="" className="glasses-pic" src={GlassesW}/>
            <img alt="" className="profile-pic" src={ProfilePic}/>
          </div>
          <div className="right">
            <div className="bio">
              <p>
              Currently, I am a senior undergraduate student studying computer engineering at <a href="https://en.kntu.ac.ir" target="_blank">K. N. Toosi University of Technology</a>. I am broadly enthusiastic about doing research in Artificial Intelligence area, more specifically anything linked with Reinforcement Learning, from theoretical concepts to practical applications. </p>
              <p>
              I am fortunate to work with <a href="https://wp.kntu.ac.ir/khasteh/">Prof. S. H. Khasteh</a> on enhancing Federated Learning's challenges using Deep Reinforcement Learning and <a href="https://wp.kntu.ac.ir/f.sheikhi/" target="_blank">Prof. F. Sheikhi</a> on designing Deep Learning models to transfer styles and generate artistic images.
              I am also delighted to research Deep Learning approaches for creating deep end-to-end models in Parallel and Cabel Robot section of <a href="https://aras.kntu.ac.ir" target="_blank">ARAS (Advanced Robotic and Autonomous Systems)</a> research group under the supervision of <a href="https://aras.kntu.ac.ir/taghirad/" target="_blank">Professor H. D. Taghirad</a> and <a href="https://ir.linkedin.com/in/s-ahmad-khalilpour-9aaba14b" target="_blank"> Dr. S. A. Khalilpour</a>.
              </p>
              <p>
              {/* Alongside the mentioned affiliations, I strengthen my knowledge and hone my skills and ideas independently by implementing and combining the renowned problems in AI. Some of my projects are listed below; for further information and detailed implementation, please refer to <a href="https://github.com/troddenspade" target="_blank">my GitHub page</a>. */}
              Alongside the mentioned affiliations, I strengthen my knowledge and hone my skills and ideas independently by implementing and combining the renowned problems in AI. Refer to <a href="https://github.com/troddenspade" target="_blank">my GitHub page</a> for further information and detailed implementation.
              </p>
            </div>
            <div className="interests">
              <h3>
                Research Interests: 
              </h3>
              <ul className="list">
                <li><span>Deep Reinforcement Learning</span></li>
                <li><span>Imitation Learning / Inverse RL</span></li>
                <li><span>Meta (Reinforcement) Learning</span></li>
                <li><span>Robotics</span></li>
                <li><span>Deep Learning</span></li>
                <li><span>Federated Learning</span></li>
              </ul>
            </div>
          </div>
      </div>
    )
  }
  
  export default About