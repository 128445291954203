import * as React from "react"

import Nav from "../components/nav"
import About from "../components/about"
import Education from "../components/education"
import Publication from "../components/publication"
import Project from "../components/project"

import "../styles/global.scss"
import Contact from "../components/contact"
import Bottom from "../components/bottom"


// markup
const IndexPage = () => {
  if (typeof window !== 'undefined') 
    localStorage.getItem("data-theme") === "dark" ? 
      document.documentElement.setAttribute("data-theme", "dark") : 
      document.documentElement.setAttribute("data-theme", "light")
    
  return (
    <main>
      <title>Parsa Samadnejad</title>
      <Nav/>
      <About/>
      <Education/>
      {/* <Publication/>
      <Project/> */}
      <Contact/>
      <Bottom/>
    </main>
  )
}

export default IndexPage
