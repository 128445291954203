import * as React from "react"

import "../styles/education.scss"

import KNTU from '../images/kntu.png'


const Education = () => {
    return (
      <div className="education" id="education">
          <div className="inner-education">
            <div className="title">
              <h2>Education</h2>
            </div>
            <div className="unis">
              <div className="uni">
                <div className="left">
                  <img alt="" src={KNTU}/>
                </div>
                <div className="right">
                  <div className="uniname">
                    <h3>K. N. Toosi University of Technology</h3>
                    <h4>B.Sc. Computer Engineering</h4>
                    <h5>Sep. 2018 - Feb. 2023</h5>
                  </div>
                  <ul className="details">
                    <li>
                      <p className="detail">Thesis: <i>Enhancement and Fast Adaptation of Federated Learning Setting using Reinforcement Learning and Model-Agnostic Meta-Learning</i></p>
                    </li>
                    {/* <li>
                      CPGA: 4.0/4.0
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
  
export default Education