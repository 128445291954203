import * as React from "react"

import "../styles/contact.scss"


const Contact = () => {
  return (
    <div className="contact" id="contact">
        <div className="body">
          <div className="title">
              <h2>Let's have a chat.</h2>
          </div>
          <div className="form-container">
              <form className="form" id="contact-form">
                <div className="inputs">
                  <div className="left">
                    <input className="input" type="text" placeholder="Name" />
                    <input className="input" type="text" placeholder="Email" />
                  </div>
                  <div className="right">
                    <textarea className={"message"} form="contact-form" placeholder="Message"></textarea>
                  </div>
                </div>
                <button className="button" type="submit" disabled>Unavailable - Please send it through Email</button>
              </form>
          </div>
        </div>
    </div>
  )
}

export default Contact